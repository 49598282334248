<template>
  <div class="holidayguru" @click="addHolidayguruClass">
    <HgHeaderBackground
      :image="image"
      :svg-language="`home-hero-${lang || $i18n.locale}-svg`"
      :showArrow="true"
      :isNewDesign="true"
    >
      <HgSearchTabs :starting-index="startingIndex" :panel-switched="true">
        <template :slot="$t('ibeSearch.title')">
          <AmadeusPackageTourSearch
            v-if="['at', 'ch', 'de'].includes(lang || $i18n.locale)"
          />

          <NetmatchPackageTourSearch
            v-if="['nl'].includes(lang || $i18n.locale)"
          />
        </template>
      </HgSearchTabs>
    </HgHeaderBackground>
  </div>
</template>

<script>
export default {
  components: {
    AmadeusPackageTourSearch: () =>
      import("../components/search/AmadeusPackageTourSearch.vue"),
    NetmatchPackageTourSearch: () =>
      import("../components/search/NetmatchPackageTourSearch.vue")
  },
  props: {
    lang: {
      type: String,
      default: ""
    },
    showMovingBadge: {
      type: Boolean,
      default: false
    },
    srcImage: {
      type: String,
      default: "/images/home-hero/Fuvahmulah_malediven_2880_1620px.jpg"
    },
    srcImagePlaceholder: {
      type: String,
      default: "/images/home-hero/Fuvahmulah_malediven_mini.jpg"
    },
    location: {
      type: String,
      default: "Fuvahmulah, Malediven"
    },
    svgColor: {
      type: String,
      default: "#323231"
    }
  },

  data: () => ({
    startingIndex: 0,
    searchTabsComponentKey: 0,
    date: new Date(),
    image: new Object()
  }),

  created() {
    this.image = {
      src: this.srcImage,
      placeholderSrc: this.srcImagePlaceholder,
      location: this.location,
      svgColor: this.svgColor
    }
  },

  computed: {
    customLinks() {
      const links = this.$i18n.t("searchCustomLinks")
      if (Array.isArray(links) && links.length > 0) return links

      return null
    }
  },

  methods: {
    addHolidayguruClass() {
      const el = document.getElementById("mobile-portal")
      if (el) el.classList.add("holidayguru")
    }
  }
}
</script>

<style lang="postcss" scoped>
.search-tabs-container {
  background-color: $teal-60;
  border-radius: 0 0 $radius-large $radius-large;
  position: relative;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.badge-in-search {
  position: absolute;
  top: rem(92);
}

@media screen and (min-width: 768px) {
  .search-tabs-container {
    padding: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .hg-search-tabs {
    max-width: 1240px;
  }

  .badge-in-search {
    top: rem(124);
  }
}
</style>

<style lang="postcss">
.hg-search-tabs .buttons:not(:last-child) {
  margin-bottom: 0;
}

.hg-search-tabs {
  width: 100%;
  position: relative;
  z-index: 20;
}

.hg-search-tab-panel {
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.15);
}
</style>
