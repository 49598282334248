import Vue from "vue";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import Cookies from "universal-cookie";
import introspectionQueryResultData from "../../apollo/fragmentTypes.json";
import { currentLocale } from "./i18n";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cookies = new Cookies();
const authToken = cookies.get("apollo-token");
window.hasAuthToken = authToken ? true : false;

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_URL,
  headers: {
    "x-locale": currentLocale,
    authorization: authToken ? `Bearer ${authToken}` : null
  }
});

// Cache
const cache = new InMemoryCache({ fragmentMatcher });
// Apollo Client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache
});

// Apollo Provider
export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

Vue.use(VueApollo);
Vue.apolloProvider = apolloProvider;
