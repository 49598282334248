<template>
  <HgAmadeusPackageTourSearch
    :is-mobile="isMobile"
    :is-loading-destinations="$apollo.queries.autosuggestAmadeus.loading"
    :airports="allAirports"
    :searched-airports="searchAirports"
    :destinations="orderedDestinations"
    :ibe-tags="amadeusPackageTourCategories"
    :ibe-url="amadeusPackageTourSearchUrl"
    :roomLabel="$t('amadeusPackageTourSearch.roomLabel')"
    :infoTextSingleRoom="$t('amadeusPackageTourSearch.infoTextSingleRoom')"
    :infoTextSingles="$t('amadeusPackageTourSearch.infoTextSingles')"
    :infoTextSingleRoomMultiple="
      $t('amadeusPackageTourSearch.infoTextSingleRoomMultiple')
    "
    :infoTextMultipleSingular="
      $t('amadeusPackageTourSearch.infoTextMultipleSingular')
    "
    :infoTextMultiplePlural="
      $t('amadeusPackageTourSearch.infoTextMultiplePlural')
    "
    :showRooms="['de', 'at'].includes($i18n.locale)"
    :show-cooperation="showCooperation"
    @onChangeAirportTerm="changeAirportTerm"
    @onUpdateDestinationTerm="updateDestinationTerm"
    @onUpdateSearchParameters="updateSearchParameters"
    @onTriggerSearch="triggerSearch"
  />
</template>

<script>
// import { mapState } from 'vuex';
import allAirportsQuery from "../../../apollo/queries/airports/allAirports.gql"
import autosuggestAmadeusQuery from "../../../apollo/queries/destination/suggest.gql"
import amadeusPackageTourCategoriesQuery from "../../../apollo/queries/ibe/amadeusPackageTourCategories.gql"
import amadeusPackageTourSearchUrlQuery from "../../../apollo/queries/ibe/amadeusPackageTourSearchUrl.gql"
import { format, addDays } from "date-fns"

// import GtmEventsMixin from '@/mixins/GtmEventsMixin';

export default {
  // mixins: [GtmEventsMixin],

  data: () => ({
    destinationTerm: "",
    orderTypes: ["Destination", "City", "Region", "Poi", "Accommodation"],
    orderedDestinations: [],
    autosuggestAmadeus: [],
    allAirports: [],
    searchAirports: [],
    searchUrl: "",
    airports: [],
    destination: null,
    dateRange: null,
    duration: null,
    travelers: { adults: 2 },
    hasParams: false,
    isMobile: false
  }),

  props: {
    showCooperation: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.isMobile = window.innerWidth < 768
  },
  mounted() {
    this.queryDefaultAirports()

    window.addEventListener("resize", () => {
      if (!window) {
        return
      }
      this.isMobile = window.innerWidth < 768
    })
  },
  watch: {
    // amadeusPackageTourSearchUrl() {
    //   this.searchUrl = this.$decorateUrl(this.amadeusPackageTourSearchUrl);
    // },
    autosuggestAmadeus() {
      if (this.autosuggestAmadeus && this.autosuggestAmadeus.length > 0) {
        // Get relevant types
        const orderTypes = []
        this.autosuggestAmadeus.forEach(elem => {
          if (!orderTypes.includes(elem.type)) {
            orderTypes.push(elem.type)
          }
        })

        // Order results by types
        const ordered = []

        orderTypes.forEach(element => {
          ordered.push(
            this.autosuggestAmadeus.filter(function(elem) {
              return elem.type === element
            })
          )
        })

        this.orderedDestinations = [].concat(...ordered)
      }
    }
  },

  methods: {
    updateDestinationTerm(term) {
      this.destinationTerm = term
    },

    updateSearchParameters(params) {
      const {
        destination,
        airports,
        dateRange,
        duration,
        travelers,
        tags
      } = params

      this.destination = destination
      if (this.destination && this.destination.__typename) {
        delete this.destination.__typename
        delete this.destination.villageName
        delete this.destination.provinceName
        delete this.destination.countryName
        delete this.destination.destinationName
      }
      this.airports =
        (airports && airports.map(airport => airport.iataCode)) || []
      this.dateRange = dateRange
      if (travelers) {
        this.travelers = travelers
      }
      this.duration = duration || ""
      this.tags = (tags && tags.map(t => t.id)) || []
      this.hasParams = true
    },

    triggerSearch() {
      this.gtmPush({
        event: "IBE_search_used",
        destination:
          this.destination && this.destination.name
            ? this.destination.name
            : "any", // Reiseziel bzw. Hotel
        period: `${format(this.dateRange.start, "EE, dd.MM.yyyy")} - ${format(
          this.dateRange.end,
          "EE, dd.MM.yyyy"
        )}`, // Zeitraum
        travelDuration: this.duration, // Reisedauer
        departureAirport: this.airports, // Da Mehrfachauswahl möglich: Mehrere Flughäfen am besten in einem Array ["FRA", "MUC"]
        travellers: this.travelers // Reisende
      })
    },

    changeAirportTerm(searchTerm) {
      this.queryAirports(searchTerm)
    },

    async queryDefaultAirports() {
      try {
        const {
          data: { allAirports }
        } = await this.$apollo.query({
          query: allAirportsQuery,
          variables: {
            filter: {
              onlyGrouped: true
            }
          }
        })
        this.allAirports = allAirports
      } catch (e) {
        // eslint-disable-next-line
        console.log("error", e.graphQLErrors)
      }
    },

    async queryAirports(searchTerm) {
      try {
        const { data } = await this.$apollo.query({
          query: allAirportsQuery,
          variables: {
            limit: 500,
            filter: {
              name: searchTerm,
              onlyGrouped: true
            }
          }
        })
        this.searchAirports = data.allAirports
      } catch (e) {
        // eslint-disable-next-line
        console.log("error", e.graphQLErrors)
      }
    }
  },

  apollo: {
    autosuggestAmadeus: {
      query: autosuggestAmadeusQuery,
      debounce: 300,
      skip() {
        return this.destinationTerm === ""
      },
      variables() {
        return {
          search: this.destinationTerm
        }
      }
    },
    amadeusPackageTourCategories: {
      query: amadeusPackageTourCategoriesQuery
    },
    amadeusPackageTourSearchUrl: {
      query: amadeusPackageTourSearchUrlQuery,
      skip() {
        return this.hasParams === false
      },
      variables() {
        return {
          input: {
            destination: this.destination,
            startDate: addDays(this.dateRange.start, 1),
            endDate: addDays(this.dateRange.end, 1),
            duration: this.duration,
            airports: this.airports,
            numberOfAdults: this.travelers.adults,
            agesOfChildren: this.travelers.ages,
            categories: this.tags
          }
        }
      }
    }
  }
}
</script>
