import { render, staticRenderFns } from "./SearchTabs.vue?vue&type=template&id=5c0aa9ea&scoped=true&"
import script from "./SearchTabs.vue?vue&type=script&lang=js&"
export * from "./SearchTabs.vue?vue&type=script&lang=js&"
import style0 from "./SearchTabs.vue?vue&type=style&index=0&id=5c0aa9ea&prod&lang=postcss&scoped=true&"
import style1 from "./SearchTabs.vue?vue&type=style&index=1&id=5c0aa9ea&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0aa9ea",
  null
  
)

export default component.exports